import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'; 


function ErrorListAlert(props) {
  const [show, setShow] = useState(true);
  const errors = props.errors

  function getInnermostValue(obj) {
    console.log(obj)
    // Itera su ogni chiave nel dizionario
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Se l'elemento è un altro oggetto, chiama ricorsivamente la funzione
        return getInnermostValue(obj[key]);
      } else {
        // Se troviamo un valore non oggetto, lo restituiamo
        return obj[key];
      }
    }
  }

    return (
      <Alert className="ms-3 me-3" variant="danger" onClose={() => setShow(false)}>
        <Alert.Heading>
            <span>
                <FontAwesomeIcon className='me-4' icon={faExclamationCircle} />
                Form Incompleto
            </span>    
        </Alert.Heading>

        <div>
        {Object.entries(errors).map(([key, value]) => (
            <span key={key}>
            <b>{value.errorLabel}:</b> {value.errorMessage},
            <span class="me-2"></span>
            </span>

        ))}
        </div>
      </Alert>
    );

}

export default ErrorListAlert;