import React, {useEffect, useMemo, useState} from "react";
import { Row } from "react-bootstrap";
import {PageSelector} from "../searchComponents/PageSelector";
import {HeaderFilterDropdown} from "./HeaderFilterDropdown";
import {TableUtils} from "./TableUtils";
import {generateUniqueKey} from "../Utility";
import {NumberOfResultsSelector} from "../searchComponents/NumberOfResultsSelector";

export function StarTableTop({
    usePagination = true,
    actionsComponent = null
    , columns
    , hiddenColumns
    , searchObject = {}
    , totalPages = 0
    , tableConfiguration
    , tableConfigurationKey
    , withCheckBoxes
    , setColumns
    , setHiddenColumns
    , setHeaderSizes
    , setTableKey
    , onChangePage
}) {

    const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(searchObject.pageSize);

    const nextPage = useMemo(() => {
        const next = searchObject.page + searchObject.pageSize
        return next < searchObject.totalElements ? next : searchObject.totalElements
    }, [searchObject]);

    useEffect(() => {
        onChangePage({...searchObject, pageSize: selectedResultsPerPage})
    }, [selectedResultsPerPage]);

    return usePagination && (
        <div className="star-table-top">
            <div>
                {actionsComponent}
            </div>
            <div className="d-flex align-items-center justify-content-end w-50" style={{ marginRight: '15px' }}>
                <HeaderFilterDropdown
                    headers={columns}
                    hiddenColumnsProps={hiddenColumns}
                    resultsPerPage={searchObject.pageSize}
                    onCleanCache={() => {
                        tableConfiguration.cleanSearchObject();
                        window.location.reload(false);
                    }}
                    onHide={(hiddenColumns, orderedColumns) => {
                        if (tableConfiguration) {
                            tableConfiguration.setHiddenColumns(hiddenColumns);
                            tableConfiguration.setColumnOrder(orderedColumns);
                        }
                        setColumns(orderedColumns)
                        setHiddenColumns(hiddenColumns)
                        setHeaderSizes(TableUtils.prepareHeaderSizes(
                            orderedColumns,
                            hiddenColumns,
                            withCheckBoxes,
                            tableConfiguration
                        ));
                        setTableKey(generateUniqueKey(tableConfigurationKey));
                    }}
                />

                <strong className='ms-4 me-4'>{((searchObject.page) * searchObject.pageSize) + 1} - {nextPage} di {searchObject.totalElements}</strong>

                <div className="d-flex align-items-center">
                    <span style={{ minWidth: '70px' }}>Per pagina</span>
                    <NumberOfResultsSelector
                        selected={selectedResultsPerPage}
                        onChange={val => setSelectedResultsPerPage(Number(val))}
                    />
                </div>

                <div>
                    <PageSelector
                        pageNumber={searchObject.page}
                        resultsPerPage={searchObject.pageSize}
                        totalPages={totalPages}
                        onUpdateNumberOfResults={(val) => onChangePage({...searchObject, pageSize: val})}
                        onClickBack={() => onChangePage({...searchObject, page: searchObject.page - 1})}
                        onClickForward={() => onChangePage({...searchObject, page: searchObject.page + 1})}
                    />
                </div>
            </div>
        </div>
    )
}