import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { PopupError, closePopupError } from "../../../shared/PopupError";
import { PopupDelete } from "../../../shared/PopupDelete";
import { CrsSubjectModal } from "./CrsSubjectModal";
import CrsSubjectList from "./CrsSubjectList";
import { UploadReceiptModal } from "../../application-crs/receipts/UploadReceiptModal";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { ImportCrsSubjectDataModal } from "../import/ImportCrsSubjectDataModal";
import { CrsSubjectsExportModal } from "./CrsSubjectsExportModal";
import { CheckRemainingSubjects, IsLicenseValid } from "../../../shared/auth/license/LicenseChecker";
import { APPLICATION_CRS, APPLICATIONS, CRS_BUY_LINK, getEcommerceProductId, getEcommerceSubjectProductId } from "../../../../config/Constants";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { Col } from "react-bootstrap";
import { ReactComponent as ITextFile } from "../../../../styles/images/svg/file-alt.svg";
import { StarTooltip } from "../../../shared/tooltips/Tooltip";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { CrsCommunicationModal } from "../communications/CrsCommunicationModal";
import { isNotEmpty } from "../../../shared/Utility";
import { BtnFill } from "../../../shared/BtnFill";
import { handleCreateCommunicationErrorsText } from "../communications/CreateCommunicationModal";
import { SubjectTotalAndCallToAction } from "../../../shared/SubjectTotalAndCallToAction";
import { PopupSubjectLicense } from "../../../shared/PopupSubjectLicense";

class CrsSubject extends React.Component {
  constructor() {
    super();
    this.state = {
      checkedSubjects: [],
      subjectSummary: null,
      disableNewSubjectButtons: false,
      IsLicenseValid: false,
      previousYear: new Date().getFullYear() - 1,
      isLoading: false,
      elaboratedCommunications: [],
      communicationErrorStrings: [],
      elaboratedCommunicationModals: [],
      currentModalIndex: null

    };
  }

  componentDidMount() {
    if (this.props?.auth?.user?.customer?.customerApplications) {
      this.checkExpire();
    }
    this.getSubjects();
    this.getLocations();
    this.getCountries();

  }


  componentDidUpdate(prev, prevState) {
    if (prev.auth?.user?.customer?.customerApplications !== this.props?.auth?.user?.customer?.customerApplications) {
      this.checkExpire();
    }
    if(prevState.subjectSummary != this.state.subjectSummary){
      if(this.state.subjectSummary?.available < 0){
          PopupSubjectLicense({ total: this.state.subjectSummary.total
              , used: this.state.subjectSummary.used
              , name: APPLICATION_CRS
              , available: this.state.subjectSummary.available });
          this.setState({isLicenseValid: false})
      }
  }
  }


  checkExpire() {
    const applications = this.props?.auth?.user?.customer?.customerApplications;
    if (applications?.length > 0) {
      const isLicenseValid = IsLicenseValid(applications, APPLICATION_CRS);

      const application = APPLICATIONS.find(a => a.description === APPLICATION_CRS);
      const customerApplication = applications?.find(ca => ca?.application?.id === application.id);

      this.setState({ customerApplication: customerApplication, isLicenseValid: isLicenseValid })
      this.getSubjectSummary(false);
    }
  }

  getSubjectSummary(showPopup) {
    CheckRemainingSubjects(APPLICATION_CRS, showPopup,
      (subjectSummary) => {
        this.setState({
          subjectSummary: subjectSummary, disableNewSubjectButtons: subjectSummary?.available < 1
        });
      });
  }
  getSubjects = () => {
    this.props.dispatch(actions.getAllCrsSubjects());
  };

  getLocations = () => {
    this.props.dispatch(actions.getAllLocations());
  };

  getCountries = () => {
    this.props.dispatch(actions.getAllCountries());
  };

  checkSubject = (subject) => {
    let newList = this.state.checkedSubjects;
    newList.push(subject);
    this.setState({ checkedSubjects: newList });
  };

  uncheckSubject = (subject) => {
    let newList = [];
    this.state.checkedSubjects.map((current) => {
      if (current.id !== subject.id) newList.push(current);
    });
    this.setState({ checkedSubjects: newList });
  };

  checkAllSubjects = () => {
    let allSubjects = this.props.subjects;
    this.setState({
      checkedSubjects: allSubjects,
    });
  };

  uncheckAllSubjects = () => {
    this.setState({
      checkedSubjects: [],
    });
  };

  openPopupDelete = () => {
    PopupDelete({
      operazione: "Soggetti Selezionati",
      handleClickConfirm: this.deleteCrsSubject,
      text: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    });
  };

  deleteCrsSubject = () => {
    this.state.checkedSubjects.map((subject) => {
      actions.deleteCrsSubject(subject.id).then(
        () => {
          this.setState({ checkedSubjects: [] });
          this.getSubjects();
          this.getSubjectSummary(false);
          PopupSuccess()
        },
        (errors) => {
          PopupError({ ...this.props, text: errors });
        }
      );
      document.getElementById(`checkboxDetails${subject.id}`).checked = false;
      let rowIds = document.querySelectorAll('[id^="row-"]');
      for (let i = 0; i < rowIds.length; i++) {
        if (rowIds[i].localName === "div") {
          rowIds[i].classList.remove("bg-cobalt", "bold");
        }
      }
    });
  };


  onClickCreateEmptyCommunications() {



    this.setState({
      isLoading: true
      , elaboratedCommunicationModals: []
    });

    let elaboratedCommunications = [];

    const subjectIds = this.state.checkedSubjects.map(subject => {
      return subject.id;

    })
    actions.createCrsCommunicationEmptyMultiple(subjectIds).then(
      res => {
        res.forEach((element, index) => {
          if (element.communication) {
            elaboratedCommunications.push(
              () => (
                <CrsCommunicationModal
                  key={"communication-modal-" + element.communication.id}
                  sentCommunications={[element.communication]}
                  showModal={true}
                  subject={element.communication.subject}
                  emailAddress={element.communication.subject?.coraSubjectSid?.email}
                  closeModal={() => this.setState({
                    currentModalIndex: elaboratedCommunications[this.state.currentModalIndex + 1] ? this.state.currentModalIndex + 1 : null
                  })}
                />
              )
            )
          } else if (element.error) {
            elaboratedCommunications.push(
              () => (<React.Fragment key={"communication-error-modal-" + index}>
                {PopupError({
                  text: element.error,
                  buttonClose: () => <BtnFill text={"CHIUDI"}
                    handlerOnClick={() => {
                      closePopupError()
                      this.setState({ currentModalIndex: elaboratedCommunications[this.state.currentModalIndex + 1] ? this.state.currentModalIndex + 1 : null })
                    }}
                  />
                })}
              </React.Fragment>)
            )
          }
        });

      }
      , err => {
        PopupError({
          text: handleCreateCommunicationErrorsText(err)
        })
      }
    )
      .then(() => {
        this.setState({
          isLoading: false
          , elaboratedCommunicationModals: elaboratedCommunications
          , currentModalIndex: elaboratedCommunications.length !== 0 ? 0 : null
        })
        this.getSubjects()
      });





  }



  render() {
    const hasNoCheckedSubjects = Object.keys(this.state.checkedSubjects).length === 0;
    return (
      <React.Fragment>
        {this.state.isLoading && <PageSpinner />}
        <div className="table-custom mt-3">
          <SubjectTotalAndCallToAction
            total={this.state.subjectSummary?.total}
            remaining={this.state.subjectSummary?.available}
            used={this.state.subjectSummary?.used}
            baseEcommerceProductId={getEcommerceProductId(APPLICATION_CRS)}
            subjectsProductId={getEcommerceSubjectProductId(APPLICATION_CRS)}
            licenseType={this.state.customerApplication?.licenseType}
            customerApplication={this.state.customerApplication}
            buyLink={CRS_BUY_LINK} />
          <div className="tab-content tab-rel">
            <div className="row header-btn-relationship ">
              <Col lg={7} className="pe-0 ps-0">
                <div className=" d-flex align-items-center">
                  <div className="pe-2 ps-0">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="action buttons"
                    >
                      <button
                        type="button"
                        className={`btn btn-outline-primary btn-empty  btn-sm  ${hasNoCheckedSubjects && "disabled"}`}
                        onClick={() => this.openPopupDelete()}
                      >
                        <IDelete
                          className={"padding-right-4px"}
                          width="12"
                          fill={` ${hasNoCheckedSubjects
                            ? "#FFFFFF"
                            : "#128186"
                            }`}
                        />
                        &nbsp; Elimina
                      </button>
                    </div>
                  </div>
                  <div className="pe-2 ps-0">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="action buttons"
                    >
                      <CrsSubjectsExportModal
                        disabled={hasNoCheckedSubjects}
                        doExport={(email) => this.ExportCrsSubject(email)}
                        checkedSubjects={this.state.checkedSubjects}
                      />
                    </div>
                  </div>
                  <div className="pe-2 ps-0">
                    <ImportCrsSubjectDataModal
                      disabled={this.state.disableNewSubjectButtons || !this.state.isLicenseValid}
                      getSubjects={this.getSubjects}
                    />
                  </div>

                  <div className="pe-2 ps-0">
                    <StarTooltip text={hasNoCheckedSubjects ? "Selezionare dall'elenco i soggetti per cui creare la comunicazione" : "Crea comunicazioni vuote per i soggetti selezionati"}>
                      <button
                        type="button"
                        className={`btn   btn-new-rel  btn-sm  ${hasNoCheckedSubjects && "disabled"}`}
                        onClick={() => {
                          /**
                           * -salva soggetti con anno 
                           * -apri modale per ogni soggetto
                           */
                          this.onClickCreateEmptyCommunications();
                        }}
                        disabled={hasNoCheckedSubjects || !this.state.isLicenseValid}
                      >
                        <ITextFile
                          className={"padding-right-4px svg"}
                          width="16"
                        />
                        &nbsp; CREA COMUNICAZIONI VUOTE {this.state.previousYear}
                      </button>

                    </StarTooltip>
                  </div>
                </div>
              </Col>
              <Col lg={5} className="d-flex justify-content-end pe-0 ps-0">
                <div className="pe-2 ps-0">
                  <UploadReceiptModal {...this.props}
                    disabled={!this.state.isLicenseValid} />
                </div>
                <CrsSubjectModal
                  subjectSummary={this.state.subjectSummary}
                  locations={this.props.locations}
                  countries={this.props.countries}
                  getSubjects={this.getSubjects}
                  disabled={!this.state.isLicenseValid}
                />
              </Col>

            </div>
            <CrsSubjectList
              subjects={this.props.subjects}
              locations={this.props.locations}
              getSubjects={this.getSubjects}
              checkSubject={this.checkSubject}
              uncheckSubject={this.uncheckSubject}
              checkAllSubjects={this.checkAllSubjects}
              uncheckAllSubjects={this.uncheckAllSubjects}
              countries={this.props.countries}
              user={this.props.auth.user}
              licenseExpired={!this.state.isLicenseValid}
              onRowClick={()=>{
                if(this.state.subjectSummary?.available <0){
                    PopupSubjectLicense({
                        total:this.state.subjectSummary?.total,
                        used: this.state.subjectSummary.used,
                        name:APPLICATION_CRS
                    })
                }
            }}
              {...this.props}
            />
          </div>
        </div>
        {
          isNotEmpty(this.state.currentModalIndex) && isNotEmpty(this.state.elaboratedCommunicationModals[this.state.currentModalIndex]) &&
          this.state.elaboratedCommunicationModals[this.state.currentModalIndex]()
        }

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  let customLocations = state.locations?.data.map((location, index) => {
    return { name: location.location, key: index };
  });
  let customCountries = state.countries?.data.map((country, index) => {
    return { name: country.code, key: country.description };
  });

  return {
    auth: state.auth,
    locations: customLocations,
    countries: customCountries,
    subjects: state.crsSubjects.data,
  };
}

export default connect(mapStateToProps)(CrsSubject);
