import React, { useState, useEffect } from 'react';
import { ReactComponent as IAnagrafica } from "../../../styles/images/svg/id-card.svg";
import { BwmInput } from "../../shared/form/BwmInput";
import * as Constants from "../../../config/Constants"
import AutoCompileCustom from "../../shared/form/AutoCompileCustom";
import { ProvinceField } from "../application-cora/relationships/ProvinceField";
import radioUnsel from "../../../styles/images/radiobutton-unselected.png";
import radioSel from "../../../styles/images/radiobutton-selected.png";
import { ReactComponent as ISave } from "../../../styles/images/svgs/regular/save.svg";
import * as actions from "../../../actions";
import { PageSpinner } from "../../shared/spinner/PageSpinner";
import { PopupError } from "../../shared/PopupError";
import { useSelector } from 'react-redux';
import getText from './labels';

export default function BlueSubjectForm(props) {
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);
    const customer = useSelector(state => state.auth.customer);
    const locations = useSelector(state => state.locations.customLocations);
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState(props.subject);
    const [denomination, setDenomination] = useState(subject?.denomination || '');
    const [fiscalCode, setFiscalCode] = useState(subject?.fiscalCode || '');
    const [address, setAddress] = useState(subject?.address || '');
    const [province, setProvince] = useState(subject?.province || '');
    const [location, setLocation] = useState(subject?.location || '');
    const [postalCode, setPostalCode] = useState(subject?.postalCode || '');
    const [email, setEmail] = useState(subject?.email || '');
    const [personType, setPersonType] = useState(subject?.personType || Constants.PNF);

    const valid = { isValid: true, msg: "" };
    const notValid = { isValid: false, msg: labels.REQUIREDFIELD };
    const [errorDenomination, setErrorDenomination] = useState(valid);
    const [errorFiscalCode, setErrorFiscalCode] = useState(valid);
    const [errorAddress, setErrorAddress] = useState(valid);
    const [errorProvince, setErrorProvince] = useState(valid);
    const [errorLocation, setErrorLocation] = useState(valid);
    const [errorPostalCode, setErrorPostalCode] = useState(valid);
    const [errorEmail, setErrorEmail] = useState(valid);

    useEffect(() => {
        if (!subject) {
            setSubject({
                ...subject,
                personType: personType,
                customer: customer

            });
        }
    })
    const handlerChangeFiscalCode = (e) => {
        setFiscalCode(e.target.value.toUpperCase());
        setSubject({
            ...subject,
            fiscalCode: e.target.value.toUpperCase()
        });
        setErrorFiscalCode(valid)
    }

    const handleFiscalCode = (val) => {
        actions.getBaseRegistryByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry) {
                    let newLocation = location || baseRegistry.location?.location || '';
                    let newDenomination = denomination || baseRegistry.companyName || '';
                    let newProvince = province || baseRegistry.location?.province || '';
                    let newPostalCode = postalCode || baseRegistry.location?.postalCode || '';
                    setSubject({
                        ...subject,
                        denomination: newDenomination,
                        location: newLocation,
                        province: newProvince,
                        postalCode: newPostalCode
                    })
                    setDenomination(newDenomination);
                    setLocation(newLocation);
                    setProvince(newProvince);
                    setPostalCode(newPostalCode);
                    setErrorDenomination(errorDenomination.isValid
                        ? valid
                        : (newDenomination
                            ? valid
                            : errorDenomination)
                    );

                }
            }
        )
    }

    const handlerChangeDenomination = (e) => {
        setDenomination(e.target.value.toUpperCase());
        setSubject({
            ...subject,
            denomination: e.target.value.toUpperCase()
        });
        setErrorDenomination(valid);
    }

    const handlerChangeAddress = (e) => {
        setAddress(e.target.value.toUpperCase());
        setSubject({
            ...subject,
            address: e.target.value.toUpperCase()
        });
        setErrorAddress(valid);
    }

    const handleInputAutocompile = (loc) => {
        const locationObject = locations?.find((e) => {
            return e.name === loc;
        });
        if (locationObject) {
            setLocation(locationObject.name?.toUpperCase() || '');
            setProvince(province || locationObject.province?.toUpperCase() || '');
            setPostalCode(postalCode || locationObject.postalCode || '');
            setSubject({
                ...subject,
                location: locationObject.name?.toUpperCase() || '',
                province: province || locationObject.province?.toUpperCase() || '',
                postalCode: postalCode || locationObject.postalCode || ''

            });
        } else {
            setLocation(loc.toUpperCase());
            setSubject({
                ...subject,
                location: loc.toUpperCase()
            });
        }
    }

    const handleChangeProvince = (e) => {
        setProvince(e.target.value.toUpperCase());
        setSubject({
            ...subject,
            province: e.target.value.toUpperCase()
        });
        setErrorProvince(valid);
    }

    const handlerChangePostalCode = (e) => {
        setPostalCode(e.target.value);
        setSubject({
            ...subject,
            postalCode: e.target.value
        });
        setErrorPostalCode(valid);
    }

    const handlePersonTypeClick = (e) => {
        setPersonType(e.target.value);
        setSubject({
            ...subject,
            personType: e.target.value
        });
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value.toUpperCase());
        setSubject({
            ...subject,
            email: e.target.value.toUpperCase()
        });
        setErrorEmail(valid);
    }

    const validateFormData = () => {
        let isValid = true
        if (!denomination) {
            setErrorDenomination(notValid)
            isValid = false;
        }
        if (!email) {
            setErrorEmail(notValid);
            isValid = false;
        }
        if (!fiscalCode) {
            setErrorFiscalCode(notValid);
            isValid = false;
        }
        if (!location) {
            setErrorLocation(notValid);
            isValid = false;
        }
        return isValid;
    }

    const saveSubject = (e) => {
        e.preventDefault();
        if (validateFormData()) {
            setLoading(true);
            actions.saveBlueSubject(subject).then(
                (res) => {
                    props.handleCloseModal();
                    props.getSubjects();
                },
                (err) => {

                    PopupError({
                        text: err.status === 511 ?
                            labels.MAXSUBJECTSREACHED
                            :
                            labels.GENERICERROR,
                        title: labels.WARNING,
                        request: labels.CONTACTSUPPORT,
                        close: labels.CLOSE
                    })
                })
                .then(() => {
                    setLoading(false);
                })
        }
    }
    return (
        <React.Fragment>
            <div className="row-detail bg-blue pt-4 pe-3 pb-4 ps-3">
                {loading && <PageSpinner text={labels.SPINNERTITLE} /> }
                <form className="d-flex crs" id={`anagrafica-${subject?.id || 0}`}>
                    <div className={"col-lg-12"}>
                        <div className="soggetto-crs big pe-4">
                            <div className='row'>
                                <h5 className="ms-4 mb-4"><IAnagrafica fill="#000" width="25" />{labels.SUBJECT}</h5>
                            </div>
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-check">
                                        <label>{labels.TYPE}</label>
                                        <div className="inputRadio mt-3" >
                                            <input hidden={true} className="form-check-input"
                                                type="radio"
                                                name={`personType-${Constants.PNF}`}
                                                checked={personType === Constants.PNF}
                                                id={`cr1${Constants.PNF}-${subject?.id || 0}`} value={Constants.PNF}
                                                onClick={handlePersonTypeClick} />
                                            <label className="form-check-label"
                                                htmlFor={`cr1${Constants.PNF}-${subject?.id || 0}`}>
                                                <img width="20" className="me-2"
                                                    src={personType === Constants.PNF ? radioSel : radioUnsel} />
                                                {labels.LEGALPERSON}
                                            </label>
                                        </div>

                                        <div className="inputRadio mt-3">
                                            <input hidden={true} className="form-check-input"
                                                type="radio"
                                                name={`personType-${Constants.PF}`}
                                                checked={personType === Constants.PF}
                                                id={`cr2${Constants.PF}-${subject?.id || 0}`} value={Constants.PF}
                                                onClick={handlePersonTypeClick} />
                                            <label className="form-check-label"
                                                htmlFor={`cr2${Constants.PF}-${subject?.id || 0}`}>
                                                <img width="20" className="me-2"
                                                    src={personType === Constants.PF ? radioSel : radioUnsel} />
                                                {labels.PHYSICALPERSON}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-10">

                                    <div className="row align-items-center">

                                        <div className="col-lg-4 ">
                                            <BwmInput name='fiscalCode'
                                                label={labels.FISCALCODE}
                                                placeholder={labels.FISCALCODE}
                                                className='form-control'
                                                value={fiscalCode}
                                                onChange={e => handlerChangeFiscalCode(e)}
                                                onBlur={e => handleFiscalCode(e.target.value)}
                                                error={errorFiscalCode}
                                            />
                                        </div>
                                        <div className="col-lg-8">
                                            <BwmInput name='denomination'
                                                label={personType === Constants.PF ? labels.FIRSTLASTNAME : labels.DENOMINATION}
                                                placeholder={personType === Constants.PF ? labels.FIRSTLASTNAME : labels.DENOMINATION}
                                                className='form-control'
                                                value={denomination}
                                                onChange={e => handlerChangeDenomination(e)}
                                                error={errorDenomination}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <BwmInput name='address'
                                                label={labels.ADDRESS}
                                                placeholder={labels.ADDRESS}
                                                className='form-control'
                                                value={address}
                                                onChange={e => handlerChangeAddress(e)}
                                                error={errorAddress}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <AutoCompileCustom
                                                placeholder={labels.LOCATION}
                                                label={labels.LOCATION}
                                                id="location"
                                                handleInputAutocompile={e => handleInputAutocompile(e)}
                                                filtro={locations}
                                                value={location || ""}
                                                error={errorLocation}
                                            />
                                        </div>
                                        <div className="col-lg-2">
                                            <ProvinceField
                                                placeholder={labels.PROVINCE}
                                                hint={labels.OUTSIDEITALY}
                                                province={province || ""}
                                                onChange={e => handleChangeProvince(e)}
                                                error={errorProvince} />
                                        </div>
                                        <div className="col-lg-2">
                                            <BwmInput
                                                name='postalCode'
                                                placeholder={labels.POSTALCODE}
                                                label={labels.POSTALCODE}
                                                className='form-control'
                                                value={postalCode}
                                                onChange={e => handlerChangePostalCode(e)}
                                                error={errorPostalCode}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <BwmInput
                                                name='email'
                                                placeholder={labels.EMAILADDRESS}
                                                label={labels.EMAILADDRESS}
                                                className='form-control'
                                                value={email}
                                                onChange={e => handleChangeEmail(e)}
                                                error={errorEmail}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                        <div
                            className="form-row mt-2 form-relationship-button justify-content-end ">
                            <button onClick={(e) => saveSubject(e)}
                                className="btn btn-primary rounded-pill btn-new-rel px-5"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; {labels.CONFIRM}
                            </button>

                        </div>
                    </div>
                </form>

            </div>
        </React.Fragment>
    )
}