import React from 'react';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import RelationshipForm from "../../application-crs/relationship-crs/RelationshipForm";

import { PopupDelete } from "../../../shared/PopupDelete";
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import "../../../../styles/relationship-form.css"
import { RelationshipRow } from "./RelationshipRow";
import { ReactComponent as IPdfFile } from "../../../../styles/images/svg/file-pdf.svg";
import { CreateCommunicationModal } from "../../application-crs/communications/CreateCommunicationModal";
import * as Constants from "../../../../config/Constants";
import axiosService from "../../../../services/axios-service";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg';
import moment from "moment";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import { ReactComponent as ISort } from "../../../../styles/images/svgs/regular/sort.svg";
import { downloadFile } from '../../../shared/Utility';
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear';


class RelationshipsList extends React.Component {

    constructor(props) {
        super(props);
        this.rowRef = React.createRef();
        this.createCommunicationRefData = React.createRef();
        this.state = ({
            allCheck: false,
            checkedItems: new Map(),
            isDisabled: true,
            showModal: false,
            relationships: undefined,
            sortCompleteName: 1,
            sortProgressive: 1,
            sortfiscalIdentifier: 1,
            sortRelType: 1,
            sortRelationshipNumber: 1
        });

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handlerDeleteRelationship = this.handlerDeleteRelationship.bind(this);
        this.openPopupDelete = this.openPopupDelete.bind(this);
    }

    componentDidMount() {
        if(!this.props.relationships?.length){
            this.getRelationships();
        }
    }
    componentDidUpdate(prev){
        if(prev.subject.id !== this.props.subject.id){
            this.getRelationships();
        }
    }

    getRelationships = () => {
        this.props.dispatch(actions.getCrsRelationshipsBySubjectId(this.props.subject.id));
    };

    handleCheckChange(event) {
        if (event !== undefined) {
            this.state.checkedItems.set(event.target.value, event.target.checked);
            if (!event.target.checked) {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.remove("bg-cobalt", "bold");
            } else {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.add("bg-cobalt", "bold");
            }
            let count = 0;
            for (const checkedItem of this.state.checkedItems) {
                if (checkedItem[1]) {
                    this.setState({ isDisabled: false });
                    return;
                }
                if (!checkedItem[1]) {
                    count++;
                }
            }
            if (count === this.state.checkedItems.size) {
                this.setState({ isDisabled: true });
            }
        }
    }

    handleAllCheckChange = event => {
        this.setState({ allCheck: event.target.checked });
        if (event.target.checked) {
            if (this.state.checkedItems.size > 0) {
                this.state.checkedItems.clear();
            } else {
                let items = new Map();
                this.props.relationships.map((relatioship, index) => (
                    items.set(relatioship.id, true)
                ));
                let rowIds = document.querySelectorAll('[id^="row-"]');
                for (let i = 0; i < rowIds.length; i++) {
                    if (rowIds[i].localName === "div") {
                        rowIds[i].classList.add("bg-cobalt", "bold");
                    }
                }

                this.setState({ checkedItems: items })
            }
            this.setState({ isDisabled: false });
        } else {
            this.state.checkedItems.clear();
            this.setState({ isDisabled: true });
            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.remove("bg-cobalt", "bold");
                }
            }
        }
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Rapporto",
            handleClickConfirm: this.handlerDeleteRelationship
        });
    };

    handlerDeleteRelationship = () => {
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                let relationshipChecked = this.props.relationships.find(rel => rel.id === parseInt(checkedItem[0]))
                actions.deleteCrsRelationship(relationshipChecked).then(
                    () => {
                        this.getRelationships();
                    },
                    (errors) => PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR })
                )
            }
        }
        this.resetCheckBox();
    };

    resetCheckBox = () => {
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
        this.setState({ checkedItems: new Map() });
        this.setState({ allCheck: false });
        this.setState({ isDisabled: true });
        document.getElementsByName('relationshipCheck').forEach(el => {
            if (el.checked) {
                el.checked = false
            }
        })
    };


    downloadRelationshipPdfList = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: `/api/crs/custom/crs-communications/downloadRelationshipPdfList/${this.props.subject?.id}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            if (response) {
                downloadFile(response.data, this.props.subject?.companyName + "_RELATIONSHIPS.pdf");
 
                PopupSuccess({ text: "File scaricato" });
            }
        });
    };

    setModalShow = (modal) => {
        let detailsOpened = document.getElementsByClassName("bg-blue bold");
        if (null !== detailsOpened && detailsOpened.length > 0) {
            this.rowRef.current.closeAllRowDetails();
        }


        this.setState({ showModal: !modal });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    sortBy = (val) => {
        if (val) {
            let relationships = (this.state.relationships && this.state.relationships.length > 0) ? this.state.relationships : this.props.relationships;
            let relationshipsOrdered = relationships;
            switch (val) {
                case "completeName":
                    // Sort by completeName
                    relationshipsOrdered = relationships.sort((a, b) =>

                        ((a.crsRelationshipHolder?.personType === Constants.PF && b.crsRelationshipHolder?.personType === Constants.PF)
                            ?
                            (a.crsRelationshipHolder?.lastName > b.crsRelationshipHolder?.lastName)
                            :
                            (a.crsRelationshipHolder?.companyName > b.crsRelationshipHolder?.companyName)) ? this.state.sortCompleteName :

                            (
                                (b.crsRelationshipHolder?.personType === Constants.PF && a.crsRelationshipHolder?.personType === Constants.PF)
                                    ? (b.crsRelationshipHolder?.lastName > a.crsRelationshipHolder?.lastName)
                                    : (b.crsRelationshipHolder?.companyName > a.crsRelationshipHolder?.companyName)
                                        ? -1 : 0)

                    );
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortCompleteName: -this.state.sortCompleteName });
                    break;
                case "progressive":
                    // Sort by progressive
                    relationshipsOrdered = relationships.sort((a, b) => (a.progressive > b.progressive) ? this.state.sortProgressive : ((b.progressive > a.progressive) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortProgressive: -this.state.sortProgressive });
                    break;
                case "relType":
                    // Sort by relationship type
                    relationshipsOrdered = relationships.sort((a, b) => (a.crsRelationshipNumberType?.code > b.crsRelationshipNumberType?.code) ? this.state.sortRelType : ((b.crsRelationshipNumberType?.code > a.crsRelationshipNumberType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortRelType: -this.state.sortRelType });
                    break;
                case "fiscalIdentifier":
                    // Sort by fiscalIdentifier
                    relationshipsOrdered = relationships.sort((a, b) => (a.crsRelationshipHolder?.fiscalIdentifier > b.crsRelationshipHolder?.fiscalIdentifier) ? this.state.sortfiscalIdentifier : ((b.crsRelationshipHolder?.fiscalIdentifier > a.crsRelationshipHolder?.fiscalIdentifier) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortfiscalIdentifier: -this.state.sortfiscalIdentifier });
                    break;
                case "relationshipNumber":
                    // Sort by relationshipNumber
                    relationshipsOrdered = relationships.sort((a, b) => (a.relationshipNumber > b.relationshipNumber) ? this.state.sortRelationshipNumber : ((b.relationshipNumber > a.relationshipNumber) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortRelationshipNumber: -this.state.sortRelationshipNumber });
                    break;
                default:
                    return;
            }
        }
    }

    sendCommunication = () => {
        if (this.props.subject) {
            const lastYear = new Date().getFullYear() - 1;
            const referenceYear = this.props.subject.referenceYear;
            if (referenceYear < lastYear) {
                PopupAlertCommunicationYear(referenceYear, lastYear
                    , () => this.createCommunicationRefData.current.clickButtonSend()
                    , () => {
                        let subject = this.props.subject;
                        subject.referenceYear = lastYear;
                        actions.addCrsSubject(subject).then((res) => {
                            this.createCommunicationRefData.current.clickButtonSend();
                        }, (err) => {
                            PopupError(
                                {
                                    text: Constants.APPLICATION_GENERIC_ERROR
                                }
                            );
                        });
                    })

            } else {
                this.createCommunicationRefData.current.clickButtonSend();
            }
        }
    }

    renderHeaderButtons() {
        let relationships = Object.keys(this.props.relationships);
        let disabled = relationships.length === 0;
        let containsRelationships = (relationships.length > 0 && moment(this.props?.auth?.user?.customer?.customerApplications.filter(ca => ca?.application?.id === 3)[0].expiration).diff(moment()) > 0);
        return (
            <React.Fragment>
                <div className="row header-btn-relationship">
                    <div className='col-10'>
                        <div>

                            <button type="button" className={`btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm margin-left-5px ${disabled ? 'disabled' : ''}`} disabled={(relationships.length > 0) ? false : true}
                                onClick={() => this.downloadRelationshipPdfList()}><IPdfFile className={"padding-bottom-4px"} width="12" fill={` ${disabled ? '#FFFFFF' : '#128186'}`} />&nbsp; Stampa Rapporti</button>
                            <button type="button" className={`btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm margin-left-5px ${this.state.isDisabled ? 'disabled' : ''}`}
                                onClick={() => this.openPopupDelete()} disabled={this.state.isDisabled}><IDelete className={"padding-bottom-4px"} width="12" fill={` ${disabled ? '#FFFFFF' : '#128186'}`} />&nbsp; Elimina</button>

                            <CreateCommunicationModal
                                {...this.props}
                                label={!containsRelationships ? "CREA COMUNICAZIONE VUOTA" : "CREA COMUNICAZIONE"}
                                generateEmpty={!containsRelationships}
                                subjectId={this.props.subject?.id}
                                subject={this.props.subject}
                                emailAddress={this.props.subject?.coraSubjectSid?.email}
                                reloadRelationships={this.getRelationships}
                                classCustom={"display-none"}
                                ref={this.createCommunicationRefData}
                                selectedRelationship={Array.from(new Map([...this.state.checkedItems].filter(([key, value])=> value === true)).keys())}
                            />
                            <button onClick={() => this.sendCommunication()} className="btn btn-primary rounded-pill btn-new-rel px-5 margin-left-5px" >
                                <ITextFile className={"padding-right-4px svg"} width="16" fill={`#FFFFFF`} />&nbsp; CREA COMUNICAZIONE{!containsRelationships && " VUOTA"}
                            </button>
                        </div>
                    </div>
                    <div className='col-2'>
                        <button type="button" className="btn btn-primary rounded-pill btn-new-rel px-5 btn-sm float-end"
                            onClick={() => this.setModalShow(this.state.showModal)} disabled={moment(this.props?.auth?.user?.customer?.customerApplications.filter(ca => ca?.application?.id === 3)[0].expiration).diff(moment()) < 0 ? true : false}><IPlus className={"padding-bottom-4px"} width="14" fill={` ${moment(this.props?.auth?.user?.customer?.customerApplications.filter(ca => ca?.application?.id === 3)[0].expiration).diff(moment()) < 0 ? true : false ? '#128186' : '#FFFFFF'}`} />&nbsp; NUOVO RAPPORTO
                        </button>
                        <Modal
                            backdrop="static"
                            show={this.state.showModal}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter" bsPrefix="modal-create modal"
                            centered
                            onHide={this.closeModal}>
                            <div className="bar"></div>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Anagrafica Rapporto
                                    {this.props.subject.personType === Constants.PF
                                        ? <h4>{this.props.subject.lastName + " " + this.props.subject.firstName}</h4>
                                        : <h4>{this.props.subject.companyName}</h4>
                                    }
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <RelationshipForm subject={this.props.subject} getRelationships={this.getRelationships} closeModal={this.closeModal} />
                            </Modal.Body>
                        </Modal>

                    </div>

                </div>
            </React.Fragment>
        )
    }

    renderHeaderRelationships() {
        return (
            <React.Fragment>
                <div className='col w-5 text-center div-td'>
                    <input type="checkbox" checked={this.state.allCheck} onChange={this.handleAllCheckChange} />
                </div>
                <div className='col w-8 div-td'>Progressivo <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("progressive")} /></div>
                <div className='col w-12 div-td'>ID Fiscale <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalIdentifier")} /></div>
                <div className='col w-20 div-td'>Titolare <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("completeName")} /></div>
                <div className='col w-20 div-td'>Tipo Titolare</div>
                <div className='col w-8 div-td'>Numero <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("relationshipNumber")} /></div>
                <div className='col w-30 div-td'>Tipo Numero <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("relType")} /></div>
                <div className='col w-8 div-td'>Saldo</div>
            </React.Fragment>
        )
    }

    renderRelationshipRows(subject, relationships) {
        return relationships.map((relationship, index) => {
            return (
                <RelationshipRow ref={this.rowRef} subject={subject}
                    key={index}
                    index={index}
                    relationship={relationship}
                    isChecked={this.state.checkedItems.get(relationship.id)}
                    handleCheckChange={this.handleCheckChange}
                    getRelationships={this.getRelationships}
                />
            )
        });
    }

    render() {
        const { relationships, subject } = this.props;
        return (
            <React.Fragment>
                <div className="row header-btn-relationship">
                    {this.renderHeaderButtons()}
                </div>
                <div className="text-start header-table">
                    {this.renderHeaderRelationships()}
                </div>
                {this.renderRelationshipRows(subject, relationships)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        relationships: state.crsRelationships.data
    }
}

export default connect(mapStateToProps)(RelationshipsList);
