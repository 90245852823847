import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import   { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("it", it);

export class ProvinceField extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {isValid:true, msg:''}
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.error !== undefined){
            this.setState({error: nextProps.error});
        }
    }

   render(){
        const {name, province, onChange, onFocus, disabled, placeholder, hint, error, touched} = this.props;
        const inputWrapperClass = `input-wrapper ${touched && error !== undefined ? 'has-error' : ''}`;

       return (
            <div className={inputWrapperClass}>
                <FloatingLabel controlId="floatingLabel" label={placeholder}>
                    <Form.Control type={"text"}
                              name={name}
                              onFocus={onFocus}
                              onChange={onChange}
                              value={province}
                              disabled={disabled}
                              placeholder={placeholder} 
                              isInvalid={touched ? error : undefined} />
                    <div className="provInfo">
                       {hint || "*(EE per Estero)"}
                   </div>
                </FloatingLabel>

                {/* {touched && error !== undefined &&
                <div className="text-danger">{error}</div>
                } */}

            </div>        

       )
   }
}



