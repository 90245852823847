import React from 'react';
import * as actions from "../../../../actions";
import {connect} from "react-redux";
import moment from "moment";
import * as Constants from "../../../../config/Constants";
import axiosService from "../../../../services/axios-service";
import list from "../../../../styles/images/elenco.png";
import Modal from "react-bootstrap/Modal";
import {ReactComponent as ITextFile} from "../../../../styles/images/svg/file-alt.svg";
import {ReactComponent as IPdfFile} from "../../../../styles/images/svg/file-pdf.svg";
import {BtnEmpty} from "../../../shared/BtnEmpty";
import {PopupSuccess} from "../../../shared/PopupSuccess";

class CommunicationsHistoryModal extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false
        };
    }

    getHistory = (subjectId) => {
        this.props.dispatch(actions.getComureiCommunicationsHistoryBySubjectId(subjectId));
    };

    showModal = () => {
        const {subjectId} = this.props;
        this.getHistory(subjectId);
        this.props.setHistoryModal(true)
        this.setState({showModal: true});
    };

    closeModal = () => {
        this.props.closeModal()
        this.setState({showModal: false});
    };

    downloadTxt = (communication) => {
        const {subjectId} = this.props;
        let url = `/api/comurei/custom/comurei-communications/downloadTxt/${subjectId}/${communication.id}`;
        this.callDownloadService(url, communication.filename);
    };

    downloadPdf = (communication) => {
        const {subjectId} = this.props;
        let url = `/api/comurei/custom/comurei-communications/downloadPdf/${subjectId}/${communication.id}`;
        this.callDownloadService(url, communication.filename.replace(".txt", ".pdf"));
    };

    callDownloadService = (url, file) => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file);
                document.body.appendChild(link);
                link.click();
                PopupSuccess({text: "File scaricato"});
            }
        });
    };

    renderTableHeader() {
        return (
            <React.Fragment>
                <div className="text-start header-table">
                    <div className='col w-20 div-td'>Data creazione</div>
                    <div className='col w-20 div-td'>Utente</div>
                    <div className='col w-40 div-td'>Tipo Comunicazione</div>
                    <div className='col w-40 div-td'>Sezione</div>
                    <div className='col w-10 div-td'>Download</div>
                </div>
            </React.Fragment>
        )
    }

    renderRows(communications) {
        return communications.map((communication, index) => {
            let section = "";
            if (communication.registryReason) {
                section = section + "Indagini Bancarie/";
            }

            if (communication.monitoringReason) {
                section = section + "Monitoraggio Fiscale/";
            }

            if (communication.factaReason) {
                section = section + "FATCA/CRS";
            }

            if (section.endsWith("/")) {
                section = section.substring(0, section.length - 1);
            }
            return (
                <React.Fragment>
                    <div className={"text-start row-table"}>
                        <div className='col w-20 div-td text-truncate'>{moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}</div>
                        <div className='col w-20 div-td text-truncate'>{communication.creationUser}</div>
                        <div className='col w-40 div-td text-truncate'>{communication.communicationType.code} - {communication.communicationType.description}</div>
                        <div className='col w-40 div-td text-truncate'>{section}</div>
                        <div className='col w-10 div-td text-truncate text-center containerPopOver'>
                            <div className="txt">
                                <ITextFile width="20" fill="#128186" onClick={() => this.downloadTxt(communication)}/>
                            </div>
                            <div className="pdf">
                                <IPdfFile width="20" fill="#128186" onClick={() => this.downloadPdf(communication)}/>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        });
    }

    render() {
        const {communicationsHistory} = this.props;
        return (
            <React.Fragment>
                <button className="btn btn-outline-primary btn-table" onClick={() => this.showModal()}><img src={list} alt={"Mostra dettagli"}/></button>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-60w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>STORICO R.E.I. - <strong>{this.props.subjectName}</strong></h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderTableHeader()}
                        {this.renderRows(communicationsHistory)}
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnEmpty text="ANNULLA"  handlerOnClick={this.closeModal}/>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        communicationsHistory: state.comureiCommunications.data
    }
}

export default connect(mapStateToProps)(CommunicationsHistoryModal);


